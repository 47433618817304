<template>
  <div class="user-case">
    <div class="item" v-for="item in list" :key="item.id">
      <img :src="item.logo"/>
      <div>
        <div class="item-name">{{item.name}}</div>
        <div class="item-level">{{item.level}}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "userCase",
  data() {
    return {
      list:[
        /*{id: 1, name: '上海交通大学医学院附属仁济医院', level:'三级甲等',logo: '/imgs/product/case-1.png',},
        {id: 2, name: '上海中医药大学附属岳阳中西医结合医院', level:'三级甲等',logo: '/imgs/product/case-2.png',},
        {id: 3, name: '上海市胸科医院（暨上海交通大学附属胸科医院）', level:'三级甲等',logo: '/imgs/product/case-3.png',},
        {id: 6, name: '上海市第一妇婴保健院', level:'三级甲等',logo: '/imgs/product/case-6.png',},
        {id: 10, name: '上海市第七人民医院', level:'三级甲等',logo: '/imgs/product/case-10.png',},
        {id: 11, name: '上海长征医院', level:'三级甲等',logo: '/imgs/product/case-11.png',},
        {id: 12, name: '复旦大学附属华东医院', level:'三级甲等',logo: '/imgs/product/case-12.png',},
        {id: 13, name: '中国人民解放军第四五五医院', level:'三级甲等',logo: '/imgs/product/case-13.png',},
        {id: 14, name: '广东省深圳市宝安区人民医院', level:'三级甲等',logo: '/imgs/product/case-14.png',},
        {id: 7, name: '福州市中医院暨福州市人民医院', level:'三级甲等',logo: '/imgs/product/case-7.png',},
        {id: 4, name: '四川省西昌市人民医院', level:'三级甲等',logo: '/imgs/product/case-4.png',},
        {id: 15, name: '四川省成都市第一人民医院', level:'三级甲等',logo: '/imgs/product/case-15.png',},
        {id: 16, name: '四川省成都市妇女儿童中心医院', level:'三级甲等',logo: '/imgs/product/case-16.png',},
        {id: 17, name: '四川省成都市第二人民医院', level:'三级甲等',logo: '/imgs/product/case-17.png',},
        {id: 18, name: '四川省攀枝花市中心医院', level:'三级甲等',logo: '/imgs/product/case-18.png',},
        {id: 19, name: '四川省南充市中心医院', level:'三级甲等',logo: '/imgs/product/case-19.png',},
        {id: 20, name: '四川省自贡市第一人民医院', level:'三级甲等',logo: '/imgs/product/case-20.png',},
        {id: 21, name: '重庆宽仁康复医院', level:'二级甲等',logo: '/imgs/product/case-21.png',},
        {id: 5, name: '四川省内江市第二人民医院（前身是中国人民解放军第95军医院）', level:'三级乙等',logo: '/imgs/product/case-5.png',},
        {id: 22, name: '四川省宣汉县人民医院', level:'三级甲等',logo: '/imgs/product/case-22.png',},
        {id: 23, name: '四川省青神县人民医院', level:'二级甲等',logo: '/imgs/product/case-23.png',},
        {id: 24, name: '四川省剑阁县妇幼保健院', level:'二级甲等',logo: '/imgs/product/case-24.png',},
        {id: 25, name: '贵州省桐梓县人民医院', level:'二级甲等',logo: '/imgs/product/case-25.png',},
        {id: 26, name: '贵州省凤冈县人民医院', level:'二级甲等',logo: '/imgs/product/case-26.png',},
        {id: 8, name: '贵州省兴仁市人民医院', level:'二级甲等',logo: '/imgs/product/case-8.png',},
        {id: 27, name: '贵州省平塘县人民医院', level:'二级甲等',logo: '/imgs/product/case-27.png',},
        {id: 28, name: '甘肃省临泽县人民医院', level:'二级甲等',logo: '/imgs/product/case-28.png',},
        {id: 9, name: '甘肃省景泰县中医医院', level:'二级甲等',logo: '/imgs/product/case-9.png',},*/
        {id: 1, name: '湖北天天数链技术有限公司', level:'小微企业',logo: '/imgs/product/case-29.png',},
        {id: 2, name: '成都万宜达信息科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 3, name: '上海前置通信技术有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 4, name: '德州鲨云电子商务有限责任公司', level:'小微企业',logo: '/imgs/product/case-30.png',},
        {id: 5, name: '上海鲨云网络科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 6, name: '上海全端网络科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 7, name: '成都鲨云数字科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 8, name: '重庆数科金云科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 9, name: '贵阳鲨云数字科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 10, name: '湖北天天数链科技合伙企业（有限合伙）', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 11, name: '鲨云企业管理（成都）合伙企业（有限合伙）', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 12, name: '上海云簏信息技术合伙企业（有限合伙）', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 13, name: '深圳数科金云科技有限公司', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 14, name: '重庆金云数科科技合伙企业（有限合伙）', level:'小微企业',logo: '/imgs/product/case-31.png',},
        {id: 15, name: '中国电信股份有限公司四川分公司', level:'大型企业',logo: '/imgs/product/case-32.png',},

      ],
    }
  }
}
</script>

<style scoped lang="scss">
.user-case {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-top: .48rem;
  .item {
    box-sizing: border-box;
    display: flex;
    color: $colorL1;
    font-size: 12px;
    width: 100%;
    padding: .15rem .29rem;
    margin-bottom: .25rem;
    background-color: #ffffff;
    box-shadow: 0 0 .06rem .02rem rgba(0, 0, 0, 0.1);
    border-radius: .08rem;
  
    &:last-child {
      margin-bottom: 0;
    }

    img {
      width: .96rem;
      height: .96rem;
      margin-right: .18rem;
    }
    .item-name {
      line-height: .33rem;
      font-size: .24rem;
      font-weight: 400;
      color: #000000;
      margin-bottom: .16rem;
    }
    .item-level {
      line-height: .33rem;
      font-size: .24rem;
      font-weight: 400;
      color: #6D7278;
    }
  }
}
</style>
