<template>
  <div>
    <el-dialog
        :visible.sync="dialogVisible"
        :fullscreen="true"
        :before-close="handleClose"
        :show-close="false"
    >
      <div slot="title">
        <div class="top-position">
          <p class="top-title">产品亮点</p>
          <div class="close-position">
            <img
                class="close-icon"
                src="/imgs/productHighlights/close.png"
                alt=""
                @click="handleClose"
            />
          </div>
        </div>
      </div>
      <div>
        <img class="image" src="/imgs/productHighlights/01.png" alt="">

<!--        <img class="image" src="/imgs/productHighlights/h-2-01.png" alt="">
        <img class="image" src="/imgs/productHighlights/h-2-02.png" alt="">
        <img class="image" src="/imgs/productHighlights/h-2-03.png" alt="">
        <img class="image" src="/imgs/productHighlights/h-2-04.png" alt="">
        <img class="image" src="/imgs/productHighlights/h-2-05.jpg" alt="">
        <img class="image" src="/imgs/productHighlights/h-2-06.png" alt="">

        <img class="image" src="/imgs/productHighlights/h-3-01.jpg" alt="">
        <img class="image" src="/imgs/productHighlights/h-3-02.jpg" alt="">
        <img class="image" src="/imgs/productHighlights/h-3-03.jpg" alt="">-->

        <img class="image" src="/imgs/productHighlights/02.png" alt="">
        <img class="image" src="/imgs/productHighlights/03.png" alt="">
        <img class="image" src="/imgs/productHighlights/04.png" alt="">
        <img class="image" src="/imgs/productHighlights/05.png" alt="">
        <img class="image" src="/imgs/productHighlights/06.png" alt="">
        <img class="image" src="/imgs/productHighlights/07.png" alt="">
        <img class="image" src="/imgs/productHighlights/08.png" alt="">
        <img class="image" src="/imgs/productHighlights/09.png" alt="">
        <img class="image" src="/imgs/productHighlights/10.png" alt="">
        <div class="button">
          <span class="button-text" @click="gotoDetail(productId)">了解详情</span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {Dialog} from 'element-ui'

export default {
  name: "highlightsModal",
  components: {
    [Dialog.name]: Dialog,
  },
  props:{
    productId: Number,
    default: null,
  },
  data() {
    return {
      dialogVisible: false,
    }
  },
  methods: {
    showDialog() {
      this.dialogVisible = true
    },

    handleClose() {
      this.dialogVisible = false;
    },

    // 跳转到指定页面
    gotoDetail(id) {
      this.$router.push('/detail/'+id)
    },
  }

}
</script>

<style scoped lang="scss">
/deep/ .el-dialog {
  border-radius: 0;
}

/deep/ .el-dialog .el-dialog__body {
  padding: 0;
}

/deep/ .el-dialog .el-dialog__header {
  padding: 0;
  height: 40px;
}

.top-position {
  width: 100%;
  height: 40px;
  background-image: url("/imgs/productHighlights/banner-top.png");
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  .top-title {
    font-size: 16px;
    line-height: 16px;
    color: #FFFFFF;
    font-weight: 700;
  }

  .close-position {
    position: absolute;
    right: 12px;
    top: 9px;

    .close-icon {
      width: 22px;
      height: 100%;
      cursor: pointer;
    }
  }
}

.image {
  display: block;
  width: 100%;
  height: 100%;
}

.button {
  text-align: center;

  .button-text {
    display: inline-block;
    width: 3rem;
    height: .8rem;
    font-size: .28rem;
    text-align: center;
    line-height: .8rem;
    border-radius: .06rem;
    background-color: $colorA;
    color: #ffffff;
    cursor: pointer;
    margin-bottom: 12px;
  }
}
</style>
