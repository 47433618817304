<template>
  <div>
    <div class="product_content">
      <nav-header :pageList="pageList"></nav-header>
      <!-- <page-nav :page-list="pageList"></page-nav> -->
      <div class="content_img1" id="content_img1">
        <swiper ref="mySwiper" :options="swiperOptions">
          <!-- <swiper-slide>
            <img src="/imgs/product/banner-home5.jpg" alt="" @click="gotoDetail(companyProductList[0].productId)"/>
          </swiper-slide> -->
          <swiper-slide>
            <img src="/imgs/product/banner-home4.jpg" alt="" @click="gotoDetail(1)"/>
          </swiper-slide>
          <swiper-slide>
            <img src="/imgs/product/banner-home7.jpg" alt="" @click="gotoDetail(17)"/>
          </swiper-slide>
          <swiper-slide>
            <img src="/imgs/product/banner-home1.jpg" alt="" @click="gotoDetail(companyProductList[0].productId)"/>
          </swiper-slide>
          <swiper-slide>
            <img src="/imgs/product/banner-home2.jpg" alt="" @click="gotoDetail(companyProductList[0].productId)" />
          </swiper-slide>
          <swiper-slide>
            <img src="/imgs/product/banner-home3.jpg" alt="" @click="gotoDetail(companyProductList[0].productId)" />
          </swiper-slide>
          <div class="swiper-pagination" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"></div>
          <div class="swiper-button-next" slot="button-next"></div> -->
        </swiper>
      </div>
      <!-- 公司简介 -->
      <div class="content_box1" id="content_box1">
        <div class="Level1-title">
          入驻平台的公司简介
          <!-- <div>（厂商自拟）</div> -->
        </div>
        <div class="content_box1_in">
          <img :src="companyInfoData.companyLogo"/>
          <div class="company_describe">
            <div class="company_info_text font-28 font-col-333">
              <template v-if="!isShowDetails">
                {{ interceptText(companyInfoData.companyIntroduction) + '...' }}
                  <span class="col-blue" @click="() => isWatchDetails(true)">查看详情</span>
              </template>
              <template v-else>
                {{ companyInfoData.companyIntroduction}}
                  <span class="col-blue" @click="() => isWatchDetails(false)">收起详情</span>
              </template>
            </div>
            <div class="font-col-gray font-26 margin-btm-8">客服电话: {{companyInfoData.customerWay}}</div>
            <div class="font-col-gray font-26">客服邮箱: {{companyInfoData.customerEmail}}</div>
          </div>
        </div>
        <span class="btn" @click="consultClick">立即咨询</span>
      </div>

      <!--公司产品-->
      <div class="content_product_box" id="content_product_box">
        <div class="container">
          <div class="product_position">
            <div class="Level1-title">公司产品</div>
            <div @click="openHighlights">
              <span class="product_highlights">产品亮点</span>
            </div>
          </div>
          <div class="content_product_item" v-for="item in companyProductList" :key="item.id">
            <div class="header">
              <div class="header-title">
                <img class="header-logo" :src="item.productLogo" />
                <span class="header-info-title">{{item.productName}}</span>
              </div>
              <div class="header-time">发布于{{item.createTime}}</div>
              <div class="header-purpose">
                <span>{{item.chainStatus == 1?'产品已上链确权':'产品未上链确权'}}</span>
                <span> | 适用于:{{item.environmentList}}</span>
              </div>
            </div>
            <div class="describe font-col-333 font-28">
              {{item.productIntroduction}}
            </div>
            <div class="btn" @click="gotoDetail(item.productId)">查看详情</div>
          </div>
        </div>

      </div>

      <!-- 用户案例 -->
      <div class="content_case_box" id="content_img2">
        <div class="Level1-title">用户案例</div>
        <user-case></user-case>
      </div>
      <PageFooterMobile></PageFooterMobile>

      <!-- 联系方式 -->
      <!-- <div class="content_connect_box" id="content_connect_box">
        <div>
          <div class="connect-title">联系方式</div>
          <div class="connect_content">
            <div class="connect-item line-one">
              <span>成立时间: {{companyInfoData.foundingTime}}</span>
              <span>注册资金: {{companyInfoData.registeredFunds}}</span>
              <span>企业规模: {{companyInfoData.companyScale}}</span>
            </div>
            <div class="connect-item line-two">
              <span>联系电话: {{companyInfoData.customerWay}}</span>
              <span>客服电话: {{companyInfoData.customerWay}}</span>
            </div>
            <div class="connect-item">
              <span>企业邮箱: {{companyInfoData.email}}</span>
            </div>
            <div class="connect-item">
              <span>企业官网: {{companyInfoData.companyWebsite}}</span>
            </div>
            <div class="connect-item">
              <span>企业地址: {{companyInfoData.address}}</span>
            </div>
          </div>
          <div class="divide"></div>
          <div class="connect-footer">
            <img class="connect-img" src="/imgs/logo_white.png"/>
            <div class="connect-inquiry">咨询: {{ companyInfoData.customerWay }}</div>
            <div class="connect-link">
					<dl v-for="(item, index) in footer_bottomdata" :key="index">
						<dd v-for="(item, index) in item.data" :key="index">
							<a :href="item.url" target="_blank">{{ item.name }}</a>
						</dd>
					</dl>
            </div>
          </div>
        </div>
      </div> -->
    </div>
    <consult-modal ref="consultModal"></consult-modal>
    <highlights-modal-mobile ref="highlightsModal" :productId="1"></highlights-modal-mobile>
  </div>
</template>
<script>
import moment from 'moment'
import { getInfo } from '../../api/login'
import { getCompanyProduct, getCompanyInfo } from "../../api/product";
// import PageNav from "../../components/pageNav";
import UserCase from "../../components/product-mobile/userCase";
import consultModal from "../../components/productDetail/consultModalMobile"
import NavHeader from "../../components/NavHeader-mobile.vue";
import PageFooterMobile from '../../components/pageFooterMobile.vue'
import { mapMutations, mapState } from 'vuex'
import { Swiper, SwiperSlide, directive } from "vue-awesome-swiper";
import {htmlFontSize, isMobile, setShowHighlightsModal, showHighlightsModal} from '../../util/common'
import "swiper/css/swiper.css";
import highlightsModalMobile from "@/components/productDetail/highlightsModalMobile";
export default {
  name: "product",
  components: { UserCase,consultModal,highlightsModalMobile,Swiper, SwiperSlide, NavHeader, PageFooterMobile},
  data() {
    return {
      // 视频是否显示
      videoIsShow: false,
      // 接口数据
      productsData:{},
      companyInfoData:{},
      swiperOptions: {
        autoplay: {
          delay: 3000, //1秒切换一次
          disableOnInteraction: true,
        },
        loop: true, // 循环模式选项
        // navigation: {
        //   nextEl: ".swiper-button-next",
        //   prevEl: ".swiper-button-prev",
        // },
        // 如果需要分页器
        pagination: {
          el: ".swiper-pagination",
        },
      },
      companyProductList:[],
      productId: 0,
      pageList:[
        {name: '公司首页', page:'/index', id:'content_img1'},
        {name: '公司介绍', page: '/',id:'content_box1'},
        {name: '公司产品', page: '',id:'content_product_box'},
        { name: '用户案例', page: '', id: 'content_img2' },
        {name: '医疗资讯', page: '/information',id:'/information'},
        { name: '联系方式', page: '', id: 'content_connect_box' },
      ],
      isShowDetails: false,
			footer_bottomdata: [
				{
					title: "", data: [
						{
							name: '天天数链',
							url: 'https://www.tntlinking.com'
						}
					]
				},
				{
					title: "", data: [
						{
							name: '新致软件',
							url: 'https://www.newtouch.com'
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: '医盟网',
							url: 'http://chisc.net/'*/
						}
					]
				},
				{
					title: "", data: [
						{
							/*name: 'Q医疗',
							url: 'http://www.qyiliao.com/'*/
						}
					]
				},
			],
    };
  },
  directives: {
    swiper: directive,
  },
  mounted() {
    if(!isMobile()) {
      this.$router.push({name: 'product'})
      return
    }
    htmlFontSize()
    let token = this.$cookie.get('COOKIE-ACCESS-TOKEN-KEN') || localStorage.getItem('token')
    if(token) {
      localStorage.setItem('token', token)
      getInfo().then(res => {
        if(res.code === 200) {
          this.updateUserInfo(res.data)
        }
      })
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.getData();
    if (showHighlightsModal) {
      setTimeout(() => {
        this.openHighlights();
        setShowHighlightsModal();
      }, 1000)
    }
  },
  computed: {
    ...mapState('user', ['token'])
  },
  beforeDestroy() {
    window.onresize = null
  },
  methods: {
    ...mapMutations('user', ['updateUserInfo']),
    // 查看描述详情
    isWatchDetails(value) {
      this.isShowDetails = value
    },
    // 截取文字
    interceptText(text, number = 200){
      if(!text) return
      return text.substring(0, number)
    },
    // 获取接口数据
    getData(){
      // let id = this.$route.params.id;
      getCompanyProduct({companyId:449}).then(res => {
        if(res.code === 200) {
          this.companyProductList = res.data
          if (res.data && res.data[0]) {
            this.productId = res.data[0].productId
          }
          res .data.map(item => {
            item.createTime = moment(item.createTime).format('YYYY-MM-DD')
          })
        }
      }),

      getCompanyInfo({companyId:449}).then(res => {
        if(res.code === 200) {
          this.companyInfoData = res.data
          this.companyInfoData.companyLogo = this.companyInfoData.companyLogo? this.companyInfoData.companyLogo: '/imgs/product/companyLogo.png'
        }
      })
    },
    // 跳转到指定页面
    gotoDetail(id){
      this.$router.push('/detail-mobile/'+id)
    },

    openHighlights() {
      this.$refs.highlightsModal.showDialog()
    },

    consultClick() {
      this.$refs.consultModal.showDialog()
    },
  },
};
</script>
<style scoped lang="scss">
/deep/ .swiper-slide {
  img {
    cursor: pointer;
    width: 100%;
    height: 100%;
  }
}
.swiper-button-prev,
.swiper-button-next {
  color: #a8b2c4;
  @include setsize(40px, 70px);
  &:hover {
    background: #000;
    @include setsize(40px, 70px);
    opacity: 0.4;
    border-radius: 10px;
  }
}
.swiper-pagination {
  text-align: center;
}
.swiper-button-prev {
  margin-left: 0px;
}
.product_content {
  width: 100vw;
  color: $colorL1;
  .content_img1 {
    margin: .88rem auto 0;

    .content_img1_introduce {
      font-size: 25px;
      font-weight: bold;
      letter-spacing: 10px;
    }
    .content_img1_config {
      margin-top: 20px;
      a {
        color: #000;
        font-size: 16px;
        span {
          margin-left: 15px;
          margin-right: 15px;
        }
      }
    }
    .content_img1_price {
      font-size: 40px;
      margin-top: 50px;
    }
  }

  // 公司简介
  .content_box1 {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $colorL1;
    box-sizing: border-box;
    background-color: #fff;
    padding: .4rem 0 .78rem;
    .content_box1_in {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: .4rem .27rem;
      img {
        display: block;
        width: 1.32rem;
        height: 100%;
        padding-bottom: .4rem;
      }
      .company_describe {
        .company_info_text {
          padding-bottom: .4rem;
        }
      }
    }
  }

  // 公司产品
  .content_product_box {
    width: 100vw;
    box-sizing: border-box;
    padding: .86rem 0 .58rem 0;
    .content_product_item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;
      background-color: #ffffff;
      padding: .54rem .32rem .84rem;
      border-radius: 8px;
      .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        .header-title {
          display: flex;
          align-items: center;
          .header-logo {
            display: inline-block;
            width: .5rem;
            height: 100%;
            margin-right: .2rem;
            padding-bottom: .1rem;
            vertical-align: middle;
          }
          .header-info-title {
            font-size: .28rem;
            color: #000;
          }
        }
        .header-time {
          font-size: .24rem;
        }
        .header-purpose {
          height: .98rem;
          line-height: .98rem;
          width: 100%;
          margin: .41rem 0 .31rem;
          font-size: .26rem;
          color: #4850FF;
          background: rgba(72, 80, 255, 0.1);
          border-radius: .08rem;
          text-align: center;
        }
      }
      .describe {
        padding-bottom: .4rem;
      }
    }
  }

  // 用户案例
  .content_case_box {
    padding: .69rem .37rem .55rem;
    background-color: #fff;
  }

  // 联系方式
  .Level1-title {
    color: #333;
    font-size: 0.36rem;
    font-weight: 700;
    text-align: center;
  }

  .product_position {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .product_highlights {
    background-image: linear-gradient(-135deg, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0, #56a0d8, #dc8bc3, #56a0d8, #418ae0);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    -webkit-background-size: 200% 100%;
    -webkit-animation: maskedAnimation 4s infinite linear;
    font-size: 0.36rem;
    font-weight: 700;
    cursor: pointer;
    padding: 4px;
    border:1px solid $colorA;
    border-radius: 2px;
    color: $colorA;
  }
  @keyframes maskedAnimation {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
}

.btn {
  display: inline-block;
  width: 3rem;
  height: .8rem;
  font-size: .28rem;
  text-align: center;
  line-height: .8rem;
  border-radius: .06rem;
  background-color: $colorA;
  color: #ffffff;
  cursor: pointer;
}

.col-blue {
  color: blue;
}

.font-26 {
  font-size: .26rem;
  font-weight: 400;
  line-height: .47rem;
}

.font-28 {
  font-size: .28rem;
  line-height: .5rem;
  font-weight: 400;
}

.font-col-333 {
  color: #333333;
}

.font-col-gray {
  color: #6D7278;
}

.margin-btm-8 {
  margin-bottom: .08rem;
}

.container {
  box-sizing: border-box;
  width: 100%;
  padding: 0 .27rem;
}
.v-modal{
  z-index: 20 !important;
}
@media only screen and (max-width: 800px) {
   /deep/.el-dialog{
        width: 100% !important;
        height: auto;
      }
    .wx-login{
      display: none;
    }
    .card{
      padding: 0;
    }

}
</style>
